<template>
	<div ref="olMap" tabindex="1" style="width: 100%; height: 450px;">
		<div id="features-controls-slot">
			<slot name="featureControls"></slot>
		</div>
		<slot name="popup"></slot>
	</div>
</template>

<script>
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import TileJSON from 'ol/source/TileJSON';
import { fromLonLat } from 'ol/proj';
import { FullScreen, defaults } from 'ol/control';
import VectorSource from 'ol/source/Vector';
import Cluster from 'ol/source/Cluster';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';
import GeoJSON from 'ol/format/GeoJSON';
import Overlay from 'ol/Overlay';
import Select from 'ol/interaction/Select';

import 'ol/ol.css';

export default {
	name: 'OpenlayersMap',
	props: {
		webGLmarkers: {
			type: Object,
			required: false,
		},
		canvasFeatures: {
			type: Array,
			required: false,
		},
		popup: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			map: null,
			overlay: null,
		};
	},
	methods: {
		setWebGLMarkers() {
			let pointsLayer = new WebGLPointsLayer({
				source: new VectorSource({
					features: new GeoJSON().readFeatures(this.webGLmarkers.geojson),
				}),
				style: this.webGLmarkers.style,
			});
			this.map.addLayer(pointsLayer);
		},
		setCanvasLayers(layers) {
			layers.forEach(layer => {
				let newLayer;
				if (layer.geojson) {
					if (layer.cluster) {
						newLayer = new VectorLayer({
							source: new Cluster({
								distance: 1,
								source: new VectorSource({
									features: new GeoJSON().readFeatures(layer.geojson),
								}),
							}),
							style: layer.style,
							visible: layer.visible == undefined || layer.visible === true,
						});
					} else {
						newLayer = new VectorLayer({
							source: new VectorSource({
								features: new GeoJSON().readFeatures(layer.geojson),
							}),
							style: layer.style,
							visible: layer.visible == undefined || layer.visible === true,
						});
					}
					this.map.addLayer(newLayer);
					this.$emit('layerAdded', { [layer.name]: newLayer });
				}
			});
		},
	},
	mounted() {
		let mapTiles = new TileLayer({
			preload: Infinity,
			transition: 0,
			source: new TileJSON({
				url: '/styles/klokantech-basic.json',
			}),
		});
		let weatherTiles = new TileLayer({
			preload: Infinity,
			transition: 0,
			source: new TileJSON({
				url: 'weathertiles',
			}),
		});
		this.map = new Map({
			target: this.$refs.olMap,
			controls: defaults({
				attribution: false,
			}).extend([new FullScreen()]),
			layers: [mapTiles],
			view: new View({
				zoom: 0,
				center: fromLonLat([0, 10]),
			}),
		});

		this.webGLmarkers && this.setWebGLMarkers();
		this.canvasFeatures && this.setCanvasLayers(this.canvasFeatures);

		if (this.popup) {
			this.overlay = new Overlay({
				element: document.getElementById(this.popup),
				positioning: 'center-left',
				stopEvent: false,
				offset: [12, 0],
			});
			this.map.addOverlay(this.overlay);
		}

		this.map.on('click', evt => {
			this.map.forEachFeatureAtPixel(evt.pixel, feature => {
				this.$emit('mapClick', feature);
				if (this.popup) {
					this.overlay.setPosition(feature.getGeometry().getCoordinates());
				}
				return true;
			});
			if (this.map.getFeaturesAtPixel(evt.pixel).length === 0) {
				if (this.popup) this.overlay.setPosition(undefined);
			}
		});
		this.$emit('mapReady', this.map);
	},
	beforeDestroy() {
		this.map.setTarget(null);
	},
	watch: {
		webGLmarkers: {
			handler(newData) {
				if (newData) {
					this.setWebGLMarkers();
				}
			},
		},
		canvasFeatures: {
			handler(newData) {
				if (newData && newData.length > 0) {
					this.setCanvasLayers(this.canvasFeatures);
				}
			},
		},
	},
};
</script>

<style>
#features-controls-slot {
	position: absolute;
	top: 0;
	width: 100%;
	display: flex;
	justify-content: center;
}
</style>
